
function stickyThing() {
  var windowTop = $(window).scrollTop();
  var thingDetailsEl = $('#thing-details');
  if (!thingDetailsEl.length>0) return;
  var thingOffset = thingDetailsEl.parent().offset();
  if (windowTop > thingOffset.top && thingDetailsEl.parent().height()>thingDetailsEl.height()) {
    thingDetailsEl.css('width', thingDetailsEl.width());
    thingDetailsEl.css('margin-right', thingOffset.right); 	
    thingDetailsEl.addClass('sticky');
    
  } else {
    thingDetailsEl.removeClass('sticky');
    thingDetailsEl.css('margin-right');
    thingDetailsEl.css('width'); 	
  }
}

$(function() {
    $(window).scroll(stickyThing);
    stickyThing();
});